:root {
  --font-family-mono: monospace;
  /* --font-family-mono: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace */

  --font-size-headline1: 96px;
  --font-size-headline2: 60px;
  --font-size-headline3: 46px;
  --font-size-headline4: 34px;
  --font-size-headline5: 20px;
  --font-size-headline6: 20px;

  --font-size-subtitle1: 16px;
  --font-size-subtitle2: 14px;

  --font-size-body1: 16px;
  --font-size-body2: 14px;

  --font-size-button: 14px;
  --font-size-caption: 14px;
  --font-size-overline: 14px;
}

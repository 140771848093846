/* default style for popup */

.popup-content > div:first-child {
}

.popup-content {
  margin: auto;
  margin-top: -0.25rem;
  background: var(--color-secondary-lightGray1);
  padding: 5px;
  border: 1px solid var(--color-secondary-darkGray1);
}
[role='tooltip'].popup-content {
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.popup-arrow {
  color: var(--color-secondary-lightGray1);
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-arrow {
  color: var(--color-secondary-lightGray1);
  stroke-width: 2px;
  stroke: var(--color-secondary-darkGray1);
  stroke-dasharray: 28px;
  stroke-dashoffset: -52px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
